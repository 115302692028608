const qa = (s, f) => document.querySelectorAll(s).forEach(f);
const qs = (s) => document.querySelector(s);

qa('.hover-bold', function(e){
    e.innerHTML = (`
        <span>
            <span class="l">${e.innerHTML}</span>
            <strong class="hover">${e.innerHTML}</strong>
        </span>
    `);
});

qs('[data-footer-menu]').innerHTML = qs('[data-nav]').innerHTML;

qa('[data-footer-menu] [data-action="open-menu-item"]', function(e) {
    e.addEventListener(touchEvent, () => {
        e.parentElement.classList.toggle('opened');
    });
});
